import NavigationComponent from '../../components/form/navigation';
import React from 'react';

class IndexPage extends React.Component {
  render() {
    return <NavigationComponent />;
  }
}

export default IndexPage;
